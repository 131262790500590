import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import MainPage from '../../components/Pages/MainPage';
import TitlePage from '../../components/Pages/TitlePage';
import DemandCategoryForm from './DemandCategoryForm';
import DemandCategoryProvider from '../../providers/demandCategory';

export default function DemandCategoryNew() {
  const navigate = useNavigate();

  const handleSubmit = async (data) => {
    await DemandCategoryProvider.create(data);
    toast.success('Tipo de Demanda criada com sucesso!');
    navigate('/demand-categories');
  };

  return (
    <>
      <TitlePage title="Listagem de Áreas de Demandas" item="Tipo de demanda" />

      <MainPage>
        <DemandCategoryForm handleSubmit={handleSubmit} />
      </MainPage>
    </>
  );
}
