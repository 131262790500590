import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalGeneric from '../ModalGeneric';
import FormGeneric from '../../Form';
import ButtonGeneric from '../../Button';
import { schemaUpdateStatusKeyDemandDefault } from '../../../validators/schemas/demands/updateStatusKey';
import InputSelect from '../../Inputs/Input_Select';
import demandsProvider from '../../../providers/demands';
import DemandCategoryProvider from '../../../providers/demandCategory';

export default function ModalCategoryDemand() {
  const dispath = useDispatch();
  const demand = useSelector((state) => state.demand.data);
  const modalType = useSelector((state) => state.modalsDemand.modalType);
  const demandCategoryReducer = useSelector(
    (state) => state.demandCategory.demandCategory,
  );
  const resetDataModal = () => {
    dispath({
      type: 'RESET_MODAL_DEMAND',
    });
  };

  const onSubmit = async (data) => {
    await demandsProvider.updateDemand(demand?.id, data);
    const demandResponse = await demandsProvider.getDemandById(demand?.id);
    dispath({
      type: 'SET_DEMANDS_STATE',
      payload: {
        data: {
          demand,
          ...demandResponse,
        },
      },
    });
    resetDataModal();
  };

  const fetch = async () => {
    const demandCategories =
      await DemandCategoryProvider.getAllDemandCategories();
    dispath({
      type: 'SET_DEMAND_CATEGORY_LIST',
      payload: {
        ...demandCategories,
      },
    });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const optionsDemandCategories = demandCategoryReducer?.demandCategories
    ?.map((demandCategory) => ({
      value: demandCategory.id,
      label: demandCategory.name,
    }))
    ?.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <ModalGeneric
      show={modalType === 'Alterar categoria'}
      close={() => resetDataModal()}
    >
      <h3 className="text-bold text-center mt-2">
        Alterar categoria da demanda
      </h3>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <FormGeneric
          schema={schemaUpdateStatusKeyDemandDefault}
          onSubmit={onSubmit}
        >
          <InputSelect
            name="categoryId"
            defaultValue={demand?.categoryId}
            label="Categoria de demanda"
            options={optionsDemandCategories}
          />
          <div
            className="mt-3 d-flex justify-content-center align-items-center"
            style={{
              width: '98%',
              bottom: '1rem',
              gap: '5rem',
            }}
          >
            <ButtonGeneric
              type="button"
              variant="secondary"
              size="lg"
              className="text-primary"
              onClick={() => resetDataModal()}
            >
              Cancelar
            </ButtonGeneric>
            <ButtonGeneric
              type="submit"
              variant="primary"
              size="lg"
              className="text-white"
            >
              Confirmar
            </ButtonGeneric>
          </div>
        </FormGeneric>
      </div>
    </ModalGeneric>
  );
}
