import Api from '.';

const DemandCategoryProvider = {
  getAll: async (params) => {
    const { data } = await Api.get('/demand-category', { params });
    return data;
  },
  getById: async (id) => {
    const { data } = await Api.get(`/demand-category/${id}`);
    return data;
  },
  create: async (demandCategory) => {
    const { data } = await Api.post('/demand-category', {
      ...demandCategory,
      deliveryTime: 2,
    });
    return data;
  },
  update: async (demandCategory) => {
    const { data } = await Api.patch(
      `/demand-category/${demandCategory.id}`,
      demandCategory,
    );
    return data;
  },
  delete: async (demandCategory) => {
    const { data } = await Api.delete(`/demand-category/${demandCategory.id}`);
    return data;
  },
  getAllDemandCategories(additionalParams = {}) {
    return this.getAll({
      ...additionalParams,
      limit: 999,
    }).then((response) => {
      return {
        demandCategories: response.rows,
      };
    });
  },
};

export default DemandCategoryProvider;
