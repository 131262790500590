/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import MappingActivityProvider from '../../../providers/MappingActivity';
import MainPage from '../../../components/Pages/MainPage';
import TitlePage from '../../../components/Pages/TitlePage';
import EditIcon from '../../../components/customIcons/EditIcon';
import ModalForm from '../../../components/Modals/ModalForm';
import InputGeneric from '../../../components/Inputs/Input_generic';
import ProgressBar from '../../../components/ProgressBar';
import ButtonGeneric from '../../../components/Button';
import InputTags from '../../../components/Inputs/Input_Tags';
import ModalGeneric from '../../../components/Modals/ModalGeneric';
import VisibilityOnIcon from '../../../components/customIcons/VisibilityOnIcon';
import RoundedPill from '../../../components/RoundedPill';
import CustomerSectorProvider from '../../../providers/CustomerSector';

const startQuestionsIndex = 1;
const endQuestionsIndex = 15;

export default function ToolsMappingActivity() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customerSector = useSelector((state) => {
    return state.CustomerSectorList;
  });

  if (customerSector.rows.length === 0) {
    CustomerSectorProvider.getAll().then((data) => {
      dispatch({
        type: 'SET_CUSTOMER_SECTOR_LIST',
        payload: { rows: data.rows, count: data.count },
      });
    });
  }

  const [showModalEditActivity, setShowModalEditActivity] = useState(false);
  const [showModalImportAnswers, setShowModalImportAnswers] = useState(false);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [actualFilledQuestions, setActualFilledQuestions] = useState(0);

  const mappingActivity = useSelector((state) => {
    return state.MappingActivityList.selected;
  });

  const { idSector, idActivity } = useParams();

  const fetch = async (loadings = true) => {
    await MappingActivityProvider.getAll({
      where: [
        {
          key: 'id',
          value: idActivity,
        },
      ],
      loadings,
    }).then((data) => {
      if (data.rows[0]) {
        for (let i = startQuestionsIndex; i <= endQuestionsIndex; i += 1) {
          if (data.rows[0][`question${i - 1}`]) {
            setActualFilledQuestions(i);
          }
        }
      }
      dispatch({
        type: 'SET_SELECTED_MAPPING_ACTIVITY',
        payload: data.rows[0],
      });
    });
  };

  useEffect(() => {
    fetch(false);
  }, [idSector, idActivity]);

  const onChange = (e) => {
    const { name, value } = e.target;
    dispatch({
      type: 'SET_SELECTED_MAPPING_ACTIVITY',
      payload: {
        ...mappingActivity,
        [name]: value,
      },
    });
  };

  const updateActivity = async (data) => {
    const mappingActivityToUpdate = {
      ...mappingActivity,
      ...data,
    };

    await MappingActivityProvider.update(mappingActivityToUpdate);
    fetch();
    setShowModalEditActivity(false);
  };

  return (
    <>
      <TitlePage
        title={
          <Row className="m-0">
            <Col className="p-0 m-0">
              <span
                className="cursor-pointer"
                onClick={() => {
                  setShowModalEditActivity(true);
                }}
              >
                {mappingActivity?.label} <EditIcon padded={false} />
              </span>
            </Col>
            <Col xs="auto">
              <ButtonGeneric
                variant="dark"
                className="text-white"
                onClick={() => {
                  setShowModalImportAnswers(true);
                }}
              >
                Importar respostas
              </ButtonGeneric>
            </Col>
            <Col xs="auto">
              <ButtonGeneric
                className="text-white"
                onClick={() => {
                  updateActivity().then(() => {
                    toast.success('Salvo com sucesso');
                  });
                }}
              >
                Salvar
              </ButtonGeneric>
            </Col>
          </Row>
        }
        border="none"
        item={`Demandas / Mapeamentos / ${mappingActivity?.label}`}
      />
      <MainPage>
        <Row className="m-0">
          <Col xs="auto">
            <strong>{actualFilledQuestions} de 15</strong>
          </Col>
          <Col />
          <Col xs="auto">
            <strong>{mappingActivity?.percentage}%</strong> das informações
            preenchidas
          </Col>
        </Row>
        <div className="mt-1" />
        <ProgressBar value={mappingActivity?.percentage} />

        <Row className="m-0 mt-2 justify-content-center">
          <InputGeneric
            name="question0"
            label="Descrição da atividade *"
            helpText="Escreva detalhadamente o que é feito na atividade."
            onChange={onChange}
            value={mappingActivity?.question0}
            required
          />
          {actualFilledQuestions >= 1 && (
            <InputTags
              name="question1"
              label="Dados pessoais coletados *"
              helpText="Informe todos os dados pessoais relacionados a pessoas físicas que a sua empresa tem acesso para executar a atividade preenchida anteriormente.Atenção! Lembre-se que esses dados podem ser obtidos tanto de forma online, como de forma pessoal e presencial e você precisa informar todos."
              onChange={onChange}
              tags={[
                {
                  label: 'Nome',
                  value: 'Nome',
                },
                {
                  label: 'CPF',
                  value: 'CPF',
                },
                {
                  label: 'E-mail',
                  value: 'E-mail',
                },
                {
                  label: 'Endereço',
                  value: 'Endereço',
                },
                {
                  label: 'Localização',
                  value: 'Localização',
                },
                {
                  label: 'Dados de pagamento',
                  value: 'Dados de pagamento',
                },
                {
                  label: 'Dados de saúde',
                  value: 'Dados de saúde',
                },
                {
                  label: 'Foto',
                  value: 'Foto',
                },
              ]}
              value={mappingActivity?.question1}
            />
          )}
          {actualFilledQuestions >= 2 && (
            <InputTags
              name="question2"
              label="Titular dos dados *"
              helpText="Indique a quem se referem os dados informados anteriormente.\nAtenção! Qualquer pessoa física que se relacione com o negócio é protegida pela LGPD, não só consumidores."
              onChange={onChange}
              tags={[
                {
                  label: 'Cliente',
                  value: 'Cliente',
                },
                {
                  label: 'Empregado',
                  value: 'Empregado',
                },
                {
                  label: 'Prestador de serviço',
                  value: 'Prestador de serviço',
                },
                {
                  label: 'Parceiro',
                  value: 'Parceiro',
                },
                {
                  label: 'Sócio',
                  value: 'Sócio',
                },
                {
                  label: 'Investidor',
                  value: 'Investidor',
                },
              ]}
              value={mappingActivity?.question2}
            />
          )}
          {actualFilledQuestions >= 3 && (
            <InputTags
              name="question3"
              label="Finalidade dos dados *"
              helpText="É necessário informar por qual(is) razão(ões) a empresa precisa de cada um desses dados pessoais.\nAtenção! Um dado pode ter mais de uma finalidade, então é importante mencionar todas. Lembre-se que a LGPD proíbe tratamentos com fins genéricos, então é necessário ser específico para estar respaldado."
              onChange={onChange}
              tags={[
                {
                  label: 'Login na plataforma',
                  value: 'Login na plataforma',
                },
                {
                  label: 'Envio de mail marketing',
                  value: 'Envio de mail marketing',
                },
                {
                  label: 'Comunicações com o cliente',
                  value: 'Comunicações com o cliente',
                },
                {
                  label: 'Emissão de nota fiscal',
                  value: 'Emissão de nota fiscal',
                },
                {
                  label: 'Entrega',
                  value: 'Entrega',
                },
                {
                  label: 'Processo seletivo',
                  value: 'Processo seletivo',
                },
                {
                  label: 'Pagamento',
                  value: 'Pagamento',
                },
                {
                  label: 'Análise de crédito',
                  value: 'Análise de crédito',
                },
              ]}
              value={mappingActivity?.question3}
            />
          )}
          {actualFilledQuestions >= 4 && (
            <InputTags
              name="question4"
              label="Fonte dos dados *"
              helpText="Origem dos dados coletados."
              onChange={onChange}
              tags={[
                {
                  label: 'De terceiros',
                  value: 'De terceiros',
                },
                {
                  label: 'Diretamente do titular',
                  value: 'Diretamente do titular',
                },
                {
                  label: 'Dados públicos',
                  value: 'Dados públicos',
                },
              ]}
              value={mappingActivity?.question4}
            />
          )}
          {actualFilledQuestions >= 5 && (
            <InputTags
              name="question5"
              label="Manutenção dos dados *"
              helpText="Explique onde são mantidas essas informações. Em quais ativos?"
              onChange={onChange}
              tags={[
                {
                  label: 'Dispositivos móveis dos colaboradores',
                  value: 'Dispositivos móveis dos colaboradores',
                },
                {
                  label: 'Área de trabalho',
                  value: 'Área de trabalho',
                },
                {
                  label: 'Notebook da empresa',
                  value: 'Notebook da empresa',
                },
                {
                  label: 'Notebook pessoal',
                  value: 'Notebook pessoal',
                },
                {
                  label: 'Armazenamento em nuvem',
                  value: 'Armazenamento em nuvem',
                },
                {
                  label: 'Armazenamento local de computadores',
                  value: 'Armazenamento local de computadores',
                },
                {
                  label: 'Servidor local',
                  value: 'Servidor local',
                },
              ]}
              value={mappingActivity?.question5}
            />
          )}
          {actualFilledQuestions >= 6 && (
            <InputTags
              name="question6"
              label="Volume de dados *"
              helpText="Não é necessário ser um número exato, mas é importante que a empresa tenha noção da quantidade de dados tratados na atividade para avaliar o risco que a operação traz para o negócio."
              onChange={onChange}
              tags={[
                {
                  label: '10.000 dados de clientes ativos',
                  value: '10.000 dados de clientes ativos',
                },
                {
                  label: '5.000 de clientes inativos',
                  value: '5.000 de clientes inativos',
                },
                {
                  label: '40% da operação da empresa',
                  value: '40% da operação da empresa',
                },
                {
                  label: '10.000 dados por dia',
                  value: '10.000 dados por dia',
                },
              ]}
              value={mappingActivity?.question6}
            />
          )}
          {actualFilledQuestions >= 7 && (
            <InputTags
              name="question7"
              label="Dados de crianças *"
              helpText="Dados de pessoas menores de 12 anos tem proteção especial pela LGPD. Por isso, é necessário informar quais dados são esses e, no campo em branco, explicar por qual motivo são tratados."
              onChange={onChange}
              tags={[
                {
                  label: 'Não há tratamento',
                  value: 'Não há tratamento',
                },
                {
                  label: 'Nome',
                  value: 'Nome',
                },
                {
                  label: 'E-mail',
                  value: 'E-mail',
                },
                {
                  label: 'Responsável Legal',
                  value: 'Responsável Legal',
                },
                {
                  label: 'Data de Nascimento',
                  value: 'Data de Nascimento',
                },
              ]}
              value={mappingActivity?.question7}
            />
          )}
          {actualFilledQuestions >= 8 && (
            <InputTags
              name="question8"
              label="Dados pessoais sensíveis *"
              helpText="Dados pessoais sensíveis tem proteção especial pela LGPD.  Por isso, é necessário informar quais dados são esses e, no campo em branco, explicar por qual motivo são tratados."
              onChange={onChange}
              tags={[
                {
                  label: 'Origem racial ou étnica',
                  value: 'Origem racial ou étnica',
                },
                {
                  label: 'Convicção religiosa',
                  value: 'Convicção religiosa',
                },
                {
                  label: 'Opinião política',
                  value: 'Opinião política',
                },
                {
                  label:
                    'Filiação a sindicato ou a organização de caráter religioso ou filosófico ou político',
                  value:
                    'Filiação a sindicato ou a organização de caráter religioso ou filosófico ou político',
                },
                {
                  label: 'Dado referente à saúde ou à vida sexual',
                  value: 'Dado referente à saúde ou à vida sexual',
                },
                {
                  label:
                    'Dado genético ou biométrico vinculado a uma pessoa natural',
                  value:
                    'Dado genético ou biométrico vinculado a uma pessoa natural',
                },
                {
                  label: 'Não há',
                  value: 'Não há',
                },
              ]}
              value={mappingActivity?.question8}
            />
          )}
          {actualFilledQuestions >= 9 && (
            <InputTags
              name="question9"
              label="Acesso Interno *"
              helpText="Informe quais pessoas da equipe têm contato com os dados pessoais nessa atividade.\nAtenção! Se mais de uma área tiver contato com os dados, é necessário mencionar todas."
              onChange={onChange}
              tags={[
                {
                  label: 'Todos',
                  value: 'Todos',
                },
                {
                  label: 'RH',
                  value: 'RH',
                },
                {
                  label: 'Vendas',
                  value: 'Vendas',
                },
                {
                  label: 'Desenvolvimento',
                  value: 'Desenvolvimento',
                },
                {
                  label: 'Operação',
                  value: 'Operação',
                },
                {
                  label: 'Sucesso do cliente',
                  value: 'Sucesso do cliente',
                },
                {
                  label: 'Financeiro',
                  value: 'Financeiro',
                },
                {
                  label: 'Suporte',
                  value: 'Suporte',
                },
              ]}
              value={mappingActivity?.question9}
            />
          )}
          {actualFilledQuestions >= 10 && (
            <InputTags
              name="question10"
              label="Terceiros envolvidos no tratamento *"
              helpText="Indique as pessoas fora da empresa que têm acesso a esses dados no exercício da atividade, incluindo servidores, empresas de segurança, APIs e parceiros.\nAtenção! Use o campo em branco para especificar os nomes, pois a LGPD obriga que os envolvidos no compartilhamento de dados sejam informados."
              onChange={onChange}
              tags={[
                {
                  label: 'Não há',
                  value: 'Não há',
                },
                {
                  label: 'Servidor de hospedagem',
                  value: 'Servidor de hospedagem',
                },
                {
                  label: 'Servidor de armazenamento',
                  value: 'Servidor de armazenamento',
                },
                {
                  label: 'Parceiros estratégicos',
                  value: 'Parceiros estratégicos',
                },
                {
                  label: 'Desenvolvedor terceirizado',
                  value: 'Desenvolvedor terceirizado',
                },
                {
                  label: 'Ferramenta de marketing',
                  value: 'Ferramenta de marketing',
                },
                {
                  label: 'Meio de pagamento',
                  value: 'Meio de pagamento',
                },
              ]}
              value={mappingActivity?.question10}
            />
          )}
          {actualFilledQuestions >= 11 && (
            <InputTags
              name="question11"
              label="Finalidade do compartilhamento *"
              helpText="Explique por quais motivos a sua empresa precisa repassar os dados pessoais para os terceiros indicados anteriormente."
              onChange={onChange}
              tags={[
                {
                  label: 'Armazenamento seguro',
                  value: 'Armazenamento seguro',
                },
                {
                  label: 'Transações financeiras seguras',
                  value: 'Transações financeiras seguras',
                },
                {
                  label: 'Lucro',
                  value: 'Lucro',
                },
                {
                  label: 'Divulgação',
                  value: 'Divulgação',
                },
                {
                  label: 'Entrega',
                  value: 'Entrega',
                },
                {
                  label: 'Comunicação',
                  value: 'Comunicação',
                },
              ]}
              value={mappingActivity?.question11}
            />
          )}
          {actualFilledQuestions >= 12 && (
            <InputTags
              name="question12"
              label="Transferência internacional de dados *"
              helpText="Descreva os países para os quais os dados serão compartilhados e indique no campo em branco qual terceiro está localizado em cada país. É preciso informar sempre ao titular quando o dado sair do Brasil, ainda que seja apenas para armazenamento."
              onChange={onChange}
              tags={[
                {
                  label: 'Não há transferência internacional',
                  value: 'Não há transferência internacional',
                },
                {
                  label: 'Estados Unidos',
                  value: 'Estados Unidos',
                },
                {
                  label: 'União Européia',
                  value: 'União Européia',
                },
              ]}
              value={mappingActivity?.question12}
            />
          )}
          {actualFilledQuestions >= 13 && (
            <InputTags
              name="question13"
              label="Medidas de Segurança *"
              helpText="Descreva os países para os quais os dados serão compartilhados e indique no campo em branco qual terceiro está localizado em cada país. É preciso informar sempre ao titular quando o dado sair do Brasil, ainda que seja apenas para armazenamento."
              onChange={onChange}
              tags={[
                {
                  label: 'HTTPS',
                  value: 'HTTPS',
                },
                {
                  label: 'Criptografia das senhas de acesso',
                  value: 'Criptografia das senhas de acesso',
                },
                {
                  label: 'Tokens de acesso termporários',
                  value: 'Tokens de acesso termporários',
                },
                {
                  label: 'Acesso somente de agentes confiáveis',
                  value: 'Acesso somente de agentes confiáveis',
                },
                {
                  label: 'Terceirização de SI',
                  value: 'Terceirização de SI',
                },
                {
                  label: 'Treinamentos internos',
                  value: 'Treinamentos internos',
                },
                {
                  label: 'Redes próprias de trabalho',
                  value: 'Redes próprias de trabalho',
                },
              ]}
              value={mappingActivity?.question13}
            />
          )}
          {actualFilledQuestions >= 14 && (
            <InputTags
              name="question14"
              label="Exclusão definitiva dos dados *"
              helpText="Estabeleça até que momento a empresa trata ou armazena os dados dessa atividade e quando ocorre a exclusão definitiva para que a empresa não consiga mais acessá-los."
              onChange={onChange}
              tags={[
                {
                  label: 'Não há exclusão',
                  value: 'Não há exclusão',
                },
                {
                  label: 'Diante da solicitação do cliente',
                  value: 'Diante da solicitação do cliente',
                },
                {
                  label: 'Término do contrato',
                  value: 'Término do contrato',
                },
                {
                  label: 'Após 5 anos do fim do contrato',
                  value: 'Após 5 anos do fim do contrato',
                },
                {
                  label: 'Não há tempo determinado',
                  value: 'Não há tempo determinado',
                },
              ]}
              value={mappingActivity?.question14}
            />
          )}
          <div className="mt-3" />
          <Col xs="auto">
            <ButtonGeneric
              variant="outline-primary"
              onClick={() => navigate(`/tools/mapping/sector/${idSector}`)}
            >
              Voltar
            </ButtonGeneric>
          </Col>
          {actualFilledQuestions <= 14 && (
            <Col xs="auto">
              <ButtonGeneric
                className="text-white"
                onClick={() => {
                  updateActivity().then(() => {
                    toast.success('Adicionado com sucesso');
                  });
                }}
              >
                Adicionar
              </ButtonGeneric>
            </Col>
          )}
        </Row>
      </MainPage>
      <ModalForm
        close={() => setShowModalEditActivity(false)}
        open={showModalEditActivity}
        onSubmit={updateActivity}
        title="Atividade"
      >
        <InputGeneric name="label" label="Novo nome da atividade*" required />
      </ModalForm>
      <ModalGeneric
        close={() => setShowModalImportAnswers(false)}
        show={showModalImportAnswers}
        title="Importar respostas"
      >
        <h3>Importar respostas</h3>
        <h4>
          Atenção, ao importar as respostas de uma atividade você irá
          sobrescrever as respostas da atividade atual
        </h4>
        <span>Selecione um setor</span> <br />
        {customerSector?.rows?.map((sector) => (
          <RoundedPill
            key={sector.id}
            validatedSelected={selectedSector?.id !== sector.id}
            onClick={() => {
              setSelectedSector(sector);
              setSelectedActivity(null);
            }}
          >
            <Row className="m-0">
              <Col className="p-0 pe-1 ps-1 cursor-pointer" xs="auto">
                {selectedSector?.id === sector.id && (
                  <VisibilityOnIcon
                    fill="#fff"
                    width="13px"
                    height="13px"
                    padded={false}
                  />
                )}{' '}
                <span>{sector.sectorName}</span>
              </Col>
            </Row>
            <div />
          </RoundedPill>
        ))}
        {selectedSector && (
          <div>
            <span>Selecione uma atividade</span>
            <br />
            {selectedSector?.activities?.map((activity) => (
              <RoundedPill
                key={activity.id}
                validatedSelected={selectedActivity?.id !== activity?.id}
                onClick={() => {
                  setSelectedActivity(activity);
                }}
              >
                <Row className="m-0">
                  <Col className="p-0 pe-1 ps-1 cursor-pointer" xs="auto">
                    {selectedActivity?.id === activity?.id && (
                      <VisibilityOnIcon
                        fill="#fff"
                        width="13px"
                        height="13px"
                        padded={false}
                      />
                    )}{' '}
                    <span>{activity.label}</span>
                  </Col>
                </Row>
                <div />
              </RoundedPill>
            ))}
          </div>
        )}
        {selectedSector && (
          <div className="row mt-3 ">
            <div className="col" />
            <div className="col-auto">
              <ButtonGeneric
                disabled={selectedActivity === null}
                className="text-white"
                onClick={() => {
                  updateActivity({
                    ...selectedActivity,
                    sectorId: mappingActivity.sectorId,
                    id: mappingActivity.id,
                  }).then(() => {
                    toast.success('Importado com sucesso');
                    setShowModalImportAnswers(false);
                  });
                }}
              >
                importar
              </ButtonGeneric>
            </div>
          </div>
        )}
      </ModalGeneric>
    </>
  );
}
