/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { loadUFs, loadCities } from '../../../store/actions/addressActions';

export default function InfoAdress({ business }) {
  const ufs = useSelector((state) => state.addressList.ufRows);
  const cities = useSelector((state) => state.addressList.cityRows);

  useEffect(() => {
    if (!ufs.length) {
      loadUFs();
    }
    if (business?.addressState && !cities.length) {
      loadCities(business.addressState);
    }
  }, [business, cities.length, ufs.length]);

  const stateName =
    ufs.find((uf) => uf.value === Number(business?.addressState))?.label ||
    'Indefinido';
  const cityName =
    cities.find((city) => city.value === Number(business?.addressCity))
      ?.label || 'Indefinido';

  return (
    <Row className="mb-4 ms-5">
      <Row className="mb-4 mt-2">
        <Col>
          <Row className="text-bold">Cliente Internacional?</Row>
          <Row>{business?.isInternacional ? 'Sim' : 'Não'}</Row>
        </Col>
        <Col>
          <Row className="text-bold">
            {business?.isInternacional ? 'Zip Code' : 'CEP'}
          </Row>
          <Row>{business?.cep || 'Indefinido'}</Row>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Row className="text-bold">Logradouro:</Row>
          <Row>{business?.address || 'Indefinido'}</Row>
        </Col>
        <Col>
          <Row className="text-bold">Complemento:</Row>
          <Row>{business?.addressComplement || 'Indefinido'}</Row>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Row className="text-bold">Número:</Row>
          <Row>{business?.addressNumber || 'Indefinido'}</Row>
        </Col>
        <Col>
          <Row className="text-bold">Bairro:</Row>
          <Row>{business?.addressDistrict || 'Indefinido'}</Row>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Row className="text-bold">Cidade:</Row>
          <Row>{cityName || 'Indefinido'}</Row>
        </Col>
        <Col>
          <Row className="text-bold">Estado:</Row>
          <Row>{stateName || 'Indefinido'}</Row>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Row className="text-bold">Tipo de Sociedade:</Row>
          <Row>{business?.typeCompany || 'Indefinido'}</Row>
        </Col>
        <Col>
          <Row className="text-bold">Tipo de Pessoa:</Row>
          <Row>{business?.personType || 'Indefinido'}</Row>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Row className="text-bold">Tipo de Negócio:</Row>
          <Row>{business?.businessType || 'Indefinido'}</Row>
        </Col>
        <Col>
          <Row className="text-bold">CPF do Representante Legal:</Row>
          <Row>{business?.cpf || 'Indefinido'}</Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row className="text-bold">CNPJ:</Row>
          <Row>{business?.cnpj || 'Indefinido'}</Row>
        </Col>
      </Row>
    </Row>
  );
}
