import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import ModalGeneric from '../ModalGeneric';
import DEMAND_CONSTANTS from '../../../constants/demands';
import ButtonGeneric from '../../Button';
import FormGeneric from '../../Form';
import InputSelect from '../../Inputs/Input_Select';
import RichTextArea from '../../Inputs/Input_RichTextArea';
import {
  schemaUpdateDemandStatusKey,
  schemaUpdateStatusKeyInProgress,
} from '../../../validators/schemas/demands/updateStatusKey';
import s3 from '../../../providers/urlGenerator';
import ModalConfirmation from '../ModalConfirmation';
import demandsProvider from '../../../providers/demands';
import InputSLACalendar from '../../Inputs/Input_SLACalendar';
import userProvider from '../../../providers/user';
import DemandTypeProvider from '../../../providers/demandType';
import { searchBackofficeParams } from '../../../utils/params';
import DateUtils from '../../../utils/DateUtils';
import CustomerJourneyProvider from '../../../providers/CustomerJourney';
import DemandCategoryProvider from '../../../providers/demandCategory';

export default function ModalStatusKey() {
  const demand = useSelector((state) => state.demand.data);
  const modalType = useSelector((state) => state.modalsDemand.modalType);
  const statusDefault = useSelector((state) => state.modalsDemand.status);
  const users = useSelector(
    ({ backofficeList }) => backofficeList.backofficeList,
  );

  const journeyId = demand?.journey[0]?.id;

  const TypesList = useSelector(({ demandTypes }) => demandTypes.demandTypes);
  const [SLA, setSLA] = React.useState(null);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [modalNextinProgess, setModalNextinProgress] = useState(false);
  const [nextDemandJourney, setNextDemandJourney] = useState(null);
  const [nextBackofficeUser, setNextBackofficeUser] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [showInputSLA, setShowInputSLA] = useState(false);
  const [journeycustomer, setJourneyCustomer] = useState();
  const [demandTypetonxDemand, setdemandTypetonxDemand] = useState();
  const [infoDemandsInProgress, setInfoDemandsProgress] = useState([]);
  const [showCategory, setShowCategory] = useState(false);
  const demandCategoryReducer = useSelector(
    (state) => state.demandCategory.demandCategory,
  );

  const dispath = useDispatch();
  const options =
    demand?.statusKey === DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.OPEN.key
      ? [{ value: 'BLOCKED', label: 'Bloqueada' }]
      : Object.values(DEMAND_CONSTANTS.DEMAND_STATUS_KEYS)
          .filter((e) => e.isKey)
          .map((status) => ({
            value: status.key,
            label: status.description,
          }));

  const fetch = async () => {
    const response = await userProvider.getAll(searchBackofficeParams);
    const demandListTypes = await DemandTypeProvider.getAllDemandTypes();

    demandListTypes.demandTypes.map((i) => {
      if (i.name === 'Elaboração de Documentos') {
        setdemandTypetonxDemand(i);
      }
      return i;
    });

    const demandCategories =
      await DemandCategoryProvider.getAllDemandCategories();
    dispath({
      type: 'SET_DEMAND_CATEGORY_LIST',
      payload: {
        ...demandCategories,
      },
    });

    dispath({
      type: 'SET_DEMAND_TYPES_LIST',
      payload: {
        demandTypes: demandListTypes.demandTypes,
      },
    });
    dispath({
      type: 'SET_BACKOFFICE_LIST',
      payload: {
        backofficeList: response.rows,
      },
    });
    if (journeyId) {
      CustomerJourneyProvider.getById(journeyId).then((journey) => {
        setJourneyCustomer(journey.demands);
        const inProgressDemands = journey.demands.filter(
          (e) => e.statusKey === 'IN_PROGRESS' && e.id !== demand.id,
        );
        setInfoDemandsProgress(inProgressDemands);
      });
    }
    const { nextPrioritySla } = await demandsProvider.getNextPrioritySla(
      demand.id,
    );

    setSLA(DateUtils.formatToLocale(nextPrioritySla));
  };
  const navigate = useNavigate();
  useEffect(() => {
    fetch();
    if (statusDefault === DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.FINISHED.key) {
      setShowCategory(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusDefault]);

  const resetDataModal = () => {
    dispath({
      type: 'RESET_MODAL_DEMAND',
    });
  };

  const [clearRichText, setClearRichText] = useState();

  const onSubmit = async (data) => {
    if (data.statusKey === demand?.statusKey) {
      toast.error('Status não pode ser igual ao atual');
      return;
    }

    const attachments = await Promise.all(
      data?.message?.attachments?.map(async (file) => {
        const uploaders = await s3.uploadFile(file);
        return uploaders;
      }) || [],
    );

    if (data.categoryId && data.categoryId !== demand?.categoryId) {
      await demandsProvider.updateDemand(demand?.id, {
        categoryId: data.categoryId,
      });
    }

    const request = {
      statusKey: data.statusKey,
      message: data.message.message,
      attachments,
      backofficeUserId: data.backofficeUserId,
      demandTypeId: data.demandTypeId,
      deleveryForecast: data.deliveryForecast
        ? dayjs(data.deliveryForecast)
        : null,
    };
    if (!showInputSLA) {
      delete request.deleveryForecast;
    }

    const newStatus = await demandsProvider.updateDemandStatus(
      demand.id,
      request,
    );
    const demandResponse = await demandsProvider.getDemandById(demand.id);
    dispath({
      type: 'SET_DEMANDS_STATE',
      payload: {
        data: {
          ...demandResponse,
        },
      },
    });
    await clearRichText();
    const nextDemandInJourney = newStatus?.nextBlockedDemandInJourney;
    const customerid = newStatus?.customerId;
    const backofficeresponsible = newStatus?.backofficeUserId;

    if (journeycustomer) {
      let count = 0;
      journeycustomer.forEach((item) => {
        if (item.statusKey === 'IN_PROGRESS') {
          count += 1;
        }
      });

      if (nextDemandInJourney) {
        if (count >= 2) {
          setCustomerId(customerid);
          setModalNextinProgress(true);
        } else if (
          data.statusKey === DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.FINISHED.key ||
          data.statusKey === DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.PENDING.key
        ) {
          if (
            nextDemandInJourney.demandTypeId == null ||
            nextDemandInJourney.demandTypeName == null
          ) {
            try {
              const updatedType = await demandsProvider.alterDemandType(
                nextDemandInJourney.id,
                {
                  demandTypeId: demandTypetonxDemand.id,
                },
              );
              setNextDemandJourney({
                ...nextDemandInJourney,
                demandTypeId: updatedType.demandTypeId,
                demandTypeName: updatedType.demandTypeName,
              });
            } catch (error) {
              toast.error('Erro ao atualizar o tipo da próxima demanda');
            }
          } else {
            setNextDemandJourney(nextDemandInJourney);
          }
          setCustomerId(customerid);
          setNextBackofficeUser(backofficeresponsible);
          setOpenModalConfirmation(true);
        } else {
          resetDataModal();
        }
      } else {
        resetDataModal();
      }
    } else {
      resetDataModal();
    }
  };
  if (!demand) {
    return null;
  }

  const optionsDemandCategories = demandCategoryReducer?.demandCategories
    ?.map((demandCategory) => ({
      value: demandCategory.id,
      label: demandCategory.name,
    }))
    ?.sort((a, b) => a.label.localeCompare(b.label));
  return (
    <>
      <ModalConfirmation
        open={openModalConfirmation}
        buttonTextConfirm="Confirmar"
        zIndex="11"
        close={() => {
          resetDataModal();
          setOpenModalConfirmation(false);
        }}
        title="Abrir próxima demanda da jornada?"
        description={`Está ação irá alterar o status da demanda "${nextDemandJourney?.name}" para ABERTA e abrirá o painel para triagem`}
        onConfirmation={async () => {
          const request = {
            nextdemand: nextDemandJourney,
            statusKey: DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.IN_PROGRESS.key,
            backofficeUserId: nextBackofficeUser,
          };

          await demandsProvider.updateDemandStatus(
            nextDemandJourney.id,
            request,
          );
          resetDataModal();

          toast.success('Demanda aberta com sucesso');

          navigate(
            `journeys/demands-panel?customerId=${customerId}&demandId=${nextDemandJourney.id}&customerJourneyId=${journeyId}`,
          );
        }}
      />
      <ModalConfirmation
        open={modalNextinProgess}
        buttonTextConfirm="Visualizar"
        buttonTextCancel="Cancelar"
        zIndex="11"
        close={() => {
          resetDataModal();
          setModalNextinProgress(false);
        }}
        title="A próxima demanda da jornada não será desbloqueada, porque já existe outra demanda Em Andamento."
        description={
          infoDemandsInProgress.length > 0 ? (
            <>
              <br />
              <strong>
                <span>Demanda(s) Em andamento:</span>
              </strong>
              {infoDemandsInProgress.map((item) => (
                <div>
                  {item.externalId} - {item.name}
                  <br />
                </div>
              ))}
              <br />
            </>
          ) : (
            'Não há demandas em andamento'
          )
        }
        onConfirmation={async () => {
          resetDataModal();
          navigate(
            `journeys/demands-panel?customerId=${customerId}&customerJourneyId=${journeyId}`,
          );
        }}
      />
      <ModalGeneric
        style={{
          width: '80%',
          height: '100%',
          overflowY: 'auto',
        }}
        show={modalType === 'Alterar status'}
      >
        <h3>Alterar Status</h3>
        <FormGeneric
          className="d-flex flex-column justify-content-center align-items-center "
          style={{
            width: '100%',
            height: 'calc(100% - 42px)',
          }}
          onSubmit={onSubmit}
          schema={
            showInputSLA
              ? schemaUpdateStatusKeyInProgress
              : schemaUpdateDemandStatusKey
          }
        >
          <div
            style={{
              zIndex: '11',
              position: 'relative',
              width: '100%',
            }}
          >
            <InputSelect
              name="statusKey"
              label="Definir novo status"
              className={!showInputSLA ? 'mb-3' : ''}
              options={options}
              placeholder="Selecione uma opção"
              defaultValue={statusDefault}
              onChange={(e) => {
                if (e === DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.FINISHED.key) {
                  setShowCategory(true);
                } else {
                  setShowCategory(false);
                }

                if (e === DEMAND_CONSTANTS.DEMAND_STATUS_KEYS.IN_PROGRESS.key) {
                  setShowInputSLA(true);
                } else {
                  setShowInputSLA(false);
                }
              }}
            />
            {showCategory && (
              <InputSelect
                name="categoryId"
                label="Categoria de demanda"
                defaultValue={demand?.categoryId}
                options={optionsDemandCategories}
              />
            )}
            {showInputSLA && (
              <>
                <InputSelect
                  name="backofficeUserId"
                  label="Advogado"
                  placeholder="Selecione o Advogado"
                  options={users?.backofficeList
                    ?.map((e) => ({
                      value: e.id,
                      label: e.name,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))}
                  defaultValue={demand?.backofficeUserId}
                />

                <InputSelect
                  name="demandTypeId"
                  label="Tipo de demandas"
                  placeholder="Tipo de demandas"
                  options={TypesList?.demandTypes
                    ?.map((e) => ({
                      value: e.id,
                      label: e.name,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))}
                  defaultValue={demand?.demandTypeId}
                />

                <div>
                  {!demand?.priority ? (
                    <InputSLACalendar
                      className="customCalendar"
                      label="Data do SLA"
                      name="deliveryForecast"
                      defaultValue={[DateUtils.createDateFromLocale(SLA)]}
                    />
                  ) : (
                    <div className="d-flex mt-3 fs-14">
                      <span className="">SLA EXTERNO:</span>
                      <strong className="ms-2">{SLA}</strong>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div
            className="mt-3 h-100"
            style={{
              width: '100%',
            }}
          >
            <RichTextArea
              keyId={demand?.id}
              saveToType="alterStatus"
              name="message"
              setClearRichText={setClearRichText}
            />
          </div>

          <div
            className="d-flex justify-content-center align-items-center pb-3"
            style={{
              width: '98%',
              gap: '5rem',
            }}
          >
            <ButtonGeneric
              type="button"
              variant="secondary"
              size="lg"
              className="text-primary"
              onClick={() => resetDataModal()}
            >
              Cancelar
            </ButtonGeneric>
            <ButtonGeneric
              type="submit"
              variant="primary"
              size="lg"
              className="text-white"
            >
              Confirmar
            </ButtonGeneric>
          </div>
        </FormGeneric>
      </ModalGeneric>
    </>
  );
}
