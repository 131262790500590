import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import FormGeneric from './Form';
import InputGeneric from './Inputs/Input_generic';
import InputSelect from './Inputs/Input_Select';
import userProvider from '../providers/user';
import DemandsTagProvider from '../providers/demandsTag';
import { defaultGetAllParams } from '../utils/params';
import ButtonGeneric from './Button';
import FilterIcon from './customIcons/FilterIcon';
import CloseIcon from './customIcons/CloseIcon';
import DemandCategoryProvider from '../providers/demandCategory';

export default function DemandFilters() {
  const [businessUsers, setBusinessUsers] = useState([]);
  const [demandsTags, setDemandsTags] = useState([]);

  const demandsList = useSelector((state) => state.demandsList);
  const dispatch = useDispatch();
  const demandCategoryReducer = useSelector(
    (state) => state.demandCategory.demandCategory,
  );
  const fetch = async () => {
    const demandTypes = await DemandCategoryProvider.getAllDemandCategories();
    dispatch({
      type: 'SET_DEMAND_CATEGORY_LIST',
      payload: {
        ...demandTypes,
      },
    });
  };

  useEffect(() => {
    fetch();
    userProvider.getUsersToBusiness().then((response) => {
      setBusinessUsers(
        response.map((e) => ({
          value: e.id,
          label: e.name,
        })),
      );
    });
    DemandsTagProvider.getAll(defaultGetAllParams).then((response) => {
      setDemandsTags(
        response.rows.map((e) => ({
          value: e.id,
          label: e.name,
        })),
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    const fieldsFilled = Object.keys(data).filter(
      (key) => data[key] && data[key] !== 'null',
    );

    const whereToApply = fieldsFilled.map((key) => {
      if (key === 'userId') {
        return { key, value: data[key] };
      }
      if (key === 'demandsTags') {
        return { key, operator: 'contains', value: `{${data[key]}}` };
      }
      if (key === 'startDate') {
        return { key: 'createdAt', operator: 'gte', value: data[key] };
      }
      if (key === 'finalDate') {
        return { key: 'createdAt', operator: 'lte', value: data[key] };
      }
      if (key === 'name') {
        return { key, operator: 'iLike', value: data[key] };
      }
      if (key === 'DemandProcessItemContent') {
        return {
          key: 'content',
          model: 'DemandProcessItems',
          operator: 'iLike',
          value: data[key],
        };
      }

      if (key === 'categoryId' && data[key] === 'X') {
        return { key: 'categoryId', operator: 'is', value: null };
      }

      if (key === 'categoryId') {
        return { key, operator: 'eq', value: data[key] };
      }

      if (key === 'externalId') {
        return { key, operator: 'eq', value: data[key] };
      }

      return { key, value: { [key]: data[key] } };
    });

    if (
      fieldsFilled.includes('startDate') &&
      fieldsFilled.includes('finalDate')
    ) {
      whereToApply.filter((e) => e.key !== 'createdAt');
      whereToApply.push({
        key: 'createdAt',
        operator: 'between',
        value: [data.startDate, data.finalDate],
      });
    }

    const demandListWhere = [...demandsList.where];

    const whereMerged = [...whereToApply];
    demandListWhere.forEach((e) => {
      const index = whereMerged.findIndex((i) => i.key === e.key);
      if (index === -1) {
        whereMerged.push(e);
      }
    });

    return dispatch({
      type: 'SET_DEMANDS_LIST',
      payload: { where: whereMerged },
    });
  };

  const formHook = useForm();

  const cleanReduxInsertedWhere = () => {
    const keysInserted = Object.keys(formHook.getValues());
    keysInserted.push('createdAt');
    keysInserted.push('content');

    const cleanedWhere = demandsList.where.filter(
      (e) => !keysInserted.includes(e.key),
    );

    return dispatch({
      type: 'SET_DEMANDS_LIST',
      payload: { where: cleanedWhere },
    });
  };

  const optionsDemandCategories =
    demandCategoryReducer?.demandCategories
      ?.map((demandCategory) => ({
        value: demandCategory.id,
        label: demandCategory.name,
      }))
      ?.sort((a, b) => a.label.localeCompare(b.label)) || [];
  return (
    <FormGeneric useForm={formHook} onSubmit={onSubmit}>
      <Row className="m-0">
        <Col xs="4" sm="4" className="pe-2 pt-2 pb-2">
          <InputGeneric type="date" label="Data inicial" name="startDate" />
        </Col>
        <Col xs="4" sm="4" className="p-2">
          <InputGeneric type="date" label="Data final" name="finalDate" />
        </Col>
        <Col xs="4" sm="4" className="p-2">
          <InputSelect
            name="userId"
            options={businessUsers}
            label="Usuário"
            placeholder="Selecione"
          />
        </Col>
        <Col xs="4" className="p-2">
          <InputSelect
            name="demandsTags"
            options={demandsTags}
            label="Tags"
            placeholder="Selecione"
          />
        </Col>
        <Col xs="4" className="p-2">
          <InputGeneric
            label="Titulo"
            name="name"
            placeholder="digite o titulo"
          />
        </Col>
        <Col xs="4" className="p-2">
          <InputGeneric
            label="Mensagens"
            name="DemandProcessItemContent"
            placeholder="Digite a mensagem"
          />
        </Col>
        <Col xs="4" className="p-2">
          <InputGeneric
            label="Nº Demanda"
            name="externalId"
            placeholder="Digite o Nº Demanda"
          />
        </Col>
        <Col xs="4" className="p-2">
          <InputSelect
            name="categoryId"
            label="Categoria de demanda"
            options={[
              {
                value: 'X',
                label: 'Sem categoria',
              },
              ...optionsDemandCategories,
            ]}
          />
        </Col>
        <Col
          xs="auto"
          className="ps-2 pt-2 pb-2 align-self-end mb-1 ms-auto me-auto "
        >
          <ButtonGeneric type="submit" className="me-2">
            <FilterIcon padded={false} fill="#ffffff" />
          </ButtonGeneric>
          <ButtonGeneric
            type="button"
            className="ms-2"
            onClick={() => {
              cleanReduxInsertedWhere();
              formHook.reset();
            }}
            variant="danger"
          >
            <CloseIcon padded={false} fill="#ffffff" />
          </ButtonGeneric>
        </Col>
      </Row>
    </FormGeneric>
  );
}
