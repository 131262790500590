/* eslint-disable default-param-last */
const initialState = {
  demandCategory: [],
  rows: [],
  page: 1,
  limit: 10,
  count: 0,
  where: {},
  order: {},
  selectedDemandCategory: null,
};

const demandCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DEMAND_CATEGORY_LIST':
      return { ...state, demandCategory: action.payload };
    case 'SET_DEMAND_CATEGORY_LIST_STATE':
      return { ...state, ...action.payload };
    case 'SET_DEMAND_CATEGORY_LIST_SPREADING':
      return { ...state, ...action.payload };
    case 'RESET_DEMAND_CATEGORY_LIST':
      return { ...initialState };
    case 'SET_SELECTED_DEMAND_CATEGORY':
      return { ...state, selectedDemandCategory: action.payload };
    default:
      return state;
  }
};

export default demandCategoryReducer;
