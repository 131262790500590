import { combineReducers } from 'redux';

import loadingReducer from './loading';
import expandSideBarReducer from './expandSideBar';
import modalReducer from './modal';
import activiteContactReducer from './activiteContact';
import backofficeListReducer from './backofficeList';
import demandTypesReducer from './demandTypes';
import profileUserBackofficeReducer from './profileUserBackoffice';
import onboardingReducer from './onboarding';
import demandsListReducer from './demandsList';
import customerListReducer from './customerList';
import demandsStreakReducer from './demandsStreak';
import demandReducer from './demand';
import modalsDemandReducer from './modalsDemand';
import tagsListReducer from './tags';
import draftsReducer from './drafts';
import calendarReducer from './calendar';
import MarcasListReducer from './marcas';
import MarcasReducer from './marca';
import CustomerReducer from './customer';
import BusinessListReducer from './businessList';
import JourneyReducer from './journey';
import documentListReducer from './documentList';
import seachReducer from './seach';
import VacationListReducer from './vacation';
import menuListAIRReducer from './menuListAIR';
import menuBusinessAIRReducer from './menuBusinessAIR';
import eventRiskAIRReducer from './eventRiskAIR';
import questionsAIRReducer from './questionsAIR';
import feedbacksReducer from './feedbacks';
import contractReducer from './contracts';
import CustomerJourneyReducer from './CustomerJourneyList';
import usersListReducer from './usersList';
import filesReducer from './files';
import reminderReducer from './reminder';
import AsaasReducer from './asaas';
import businessesReducer from './businesses';
import reminderListReducer from './reminderList';
import filterBusinessReducer from './filterBusiness';
import demandsTagListReducer from './demandsTagList';
import CustomerMappingReducer from './CustomerMappingList';
import CustomerSectorReducer from './CustomerSectorList';
import MappingActivityReducer from './MappingActivityList';
import DemandsMetricsListReducer from './DemandsMetrics';
import DocumentsMetricsListReducer from './DocumentsMetrics';
import LayoutReducer from './layout';
import partnersListReducer from './partnersList';
import PartnerReducer from './partner';
import renewalCustomerList from './renewalCustomerList';
import EmployeesReducer from './employees';
import employeesListReducer from './employeesList';
import resignationsListReducer from './resignations';
import hiringsListReducer from './hirings';
import FoldersReducer from './folders';
import authReducer from './auth';
import sharedFolderList from './sharedFolderList';
import businessReducer from './business';
import activeTabReducer from './activeTab';
import AddressListReducer from './AddressList';
import demandCategoryReducer from './dedmandCategory';

const rootReducer = combineReducers({
  loading: loadingReducer,
  usersList: usersListReducer,
  partnersList: partnersListReducer,
  expandSideBar: expandSideBarReducer,
  modal: modalReducer,
  addressList: AddressListReducer,
  activiteContact: activiteContactReducer,
  backofficeList: backofficeListReducer,
  demandTypes: demandTypesReducer,
  demandCategory: demandCategoryReducer,
  auth: profileUserBackofficeReducer,
  onboarding: onboardingReducer,
  demandsList: demandsListReducer,
  demandsTagList: demandsTagListReducer,
  demand: demandReducer,
  customerList: customerListReducer,
  demandsStreak: demandsStreakReducer,
  modalsDemand: modalsDemandReducer,
  CustomerSectorList: CustomerSectorReducer,
  tagsList: tagsListReducer,
  drafts: draftsReducer,
  MappingActivityList: MappingActivityReducer,
  calendar: calendarReducer,
  marcasList: MarcasListReducer,
  marca: MarcasReducer,
  customer: CustomerReducer,
  partner: PartnerReducer,
  businessList: BusinessListReducer,
  journey: JourneyReducer,
  documentList: documentListReducer,
  seach: seachReducer,
  layout: LayoutReducer,
  vacation: VacationListReducer,
  menuListAIR: menuListAIRReducer,
  CustomerMappingList: CustomerMappingReducer,
  menuBusinessAIR: menuBusinessAIRReducer,
  eventeRiskAIR: eventRiskAIRReducer,
  questionsAIR: questionsAIRReducer,
  feedbacks: feedbacksReducer,
  contract: contractReducer,
  CustomerJourneyList: CustomerJourneyReducer,
  files: filesReducer,
  reminder: reminderReducer,
  reminderList: reminderListReducer,
  businesses: businessesReducer,
  business: businessReducer,
  asaas: AsaasReducer,
  demandsMetricList: DemandsMetricsListReducer,
  documentsMetricList: DocumentsMetricsListReducer,
  filterBusiness: filterBusinessReducer,
  renewalList: renewalCustomerList,
  employees: EmployeesReducer,
  employeesList: employeesListReducer,
  resignationList: resignationsListReducer,
  hiringsList: hiringsListReducer,
  folders: FoldersReducer,
  authUser: authReducer,
  sharedFolderList,
  activeTabReducer,
});

export default rootReducer;
