import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DemandCategory from '../../pages/demand-category';
import DemandCategoryNew from '../../pages/demand-category/new';
import DemandCategoryEdit from '../../pages/demand-category/edit';

export default function DemandCategoriesRoutes() {
  return (
    <Routes>
      <Route path="/" element={<DemandCategory />} />
      <Route path="/new" element={<DemandCategoryNew />} />
      <Route path="/edit/:id" element={<DemandCategoryEdit />} />
    </Routes>
  );
}
