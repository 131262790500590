import React from 'react';
import FormGeneric from '../../components/Form';
import InputGeneric from '../../components/Inputs/Input_generic';
import ButtonGeneric from '../../components/Button';
import schemaValidateDemandType from '../../validators/schemas/demandType/validator';

export default function DemandCategoryForm({
  name,
  description,
  handleSubmit,
}) {
  return (
    <FormGeneric onSubmit={handleSubmit} schema={schemaValidateDemandType}>
      <InputGeneric
        name="name"
        placeholder="Digite o nome"
        label="Nome*"
        defaultValue={name}
      />
      <InputGeneric
        name="description"
        placeholder="Digite a descrição"
        label="Descrição*"
        defaultValue={description}
      />
      <div className="pt-3 d-flex justify-content-end">
        <ButtonGeneric variant="success" className="text-white" type="submit">
          Salvar
        </ButtonGeneric>
      </div>
    </FormGeneric>
  );
}
