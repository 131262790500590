/* eslint-disable no-console */

import { toast } from 'react-toastify';
import Api from './index';

const MetricsProvider = {
  async received(loadings = false) {
    return Api.get(`/metric/receiveds`, { params: { loadings } })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getOverdueDashMetrics(loadings = false) {
    return Api.get(`/metric/overdue-customers`, { params: { loadings } })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getRevenueDashMetrics(loadings = false) {
    return Api.get(`/metric`, {
      params: {
        where: [
          {
            key: 'id',
            value: 'revenues',
          },
        ],
        loadings,
      },
    })
      .then((response) => {
        return response.data.rows[0];
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getRevenueSheetMetrics(loadings = false) {
    return Api.get(`/metric/revenues`, { params: { loadings } })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getYtMetrics(additionalParams = {}, loadings = false) {
    return Api.get('/metric/youtube', {
      params: {
        ...additionalParams,
        loadings,
      },
    });
  },
  async getGoogleAnalytics(params, loadings = false) {
    return Api.get('/metric/google-analytics', {
      params: { ...params, loadings },
    });
  },
  async getFinishedDemands(params, loadings = false) {
    return Api.get('/metric/finished-demands', {
      params: { ...params, loadings },
    });
  },
  async getFinishedDemandsThroughTime(params) {
    try {
      const response = await Api.get('/metric/finished-demands/precisely', {
        params,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      toast.error('Erro ao buscar métricas de demandas finalizadas');
      return null;
    }
  },
};

export default MetricsProvider;
