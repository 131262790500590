import * as yup from 'yup';

const schemaUpdateStatusKey = yup.object().shape({});

export const schemaUpdateStatusKeyDemandDefault = yup.object().shape({
  categoryId: yup.string().required('Campo obrigatório'),
});

export const schemaUpdateDemandStatusKey = yup.object().shape({
  statusKey: yup.string().required('Campo obrigatório'),
});

export const schemaUpdateStatusKeyInProgress = yup.object().shape({
  statusKey: yup.string().required('Campo obrigatório'),
  backofficeUserId: yup.string().required('Campo obrigatório'),
  demandTypeId: yup.string().required('Campo obrigatório'),
});

export default schemaUpdateStatusKey;
